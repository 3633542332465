<script>
import Vue from 'vue'
import { instanceNameToHostname } from '@sigmacloud/sigma-client/dist/util/instances'

export default Vue.extend({
    data() {
        return {
            instanceName: undefined,
            instance: undefined,
        }
    },
    watch: {
        instanceName() {
            this.checkInstance()
        },
    },
    beforeMount() {
        this.checkInstance()
    },
    beforeDestroy() {
        this.instance = undefined
        this.instanceName = undefined
    },
    methods: {
        checkInstance() {
            this.instanceName = this.$route.params.instanceName

            if (this.instanceName) {
                this.instance = instanceNameToHostname(this.instanceName)
            } else {
                throw new Error('Tried to load wrapper without instanceName specified!')
            }
        },
    },
})
</script>

<template>
    <div class="app-wrapper">
        <router-view name="nav" :instance="instance" :instance-name="instanceName" />
        <router-view name="body" :instance="instance" :instance-name="instanceName" />
    </div>
</template>
